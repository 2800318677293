:root {
  --danger1: #b9305c;
  --danger2: #d07461;
  --danger3: #e9b765;
  --warning1: #fffe66;
  --success1: #bffd90;
  --success2: #81fdad;
  --success3: #2cffd5;
  --info1: #01fcfe;
  --info2: #00c7f3;
  --info3: #0097e9;
  --info4: #0284fd;
  --primary: #263B94;
  --primary1: #0264d9;
  --primary2: #0031d2;
  --primary3: #0000cb;
  --dark: #000000;
  --cream: #feffc8;
  --grey: #c8c8c8;
  --white: #ffffff;
  --subcolor: #6f85c2;
  --purple: #854f94;
  --teal: #1D919E;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Roboto', sans-serif;
}

.spect-bg1 {
  background-color: var(--danger1);
}

.spect-bg2 {
  background-color: var(--danger2);
}

.spect-bg3 {
  background-color: var(--danger3);
}

.spect-bg4 {
  background-color: var(--warning1);
}

.spect-bg5 {
  background-color: var(--success1);
}

.spect-bg6 {
  background-color: var(--success2);
}

.spect-bg7 {
  background-color: var(--success3);
}

.spect-bg8 {
  background-color: var(--info1);
}

.spect-bg9 {
  background-color: var(--info2);
}

.spect-bg10 {
  background-color: var(--info3);
}

.spect-bg11 {
  background-color: var(--primary1);
}

.spect-bg12 {
  background-color: var(--primary2);
}

.spect-bg13 {
  background-color: var(--primary3);
}

.dark-bg {
  background-color: var(--dark);
}

.cream-bg {
  background-color: var(--cream);
}

.grey-bg {
  background-color: var(--grey);
}

.info-bg {
  background-color: var(--info4);
}

body,
.ant-modal-body,
.ant-modal-footer,
.ant-layout,
.flexible-modal.screen-recorder,
.flexible-modal.protocol-modal {
  background-color: #313131 !important;
}

.flexible-modal.protocol-modal .flexible-modal-resizer,
.divider-white.ant-divider-horizontal.ant-divider-with-text:before,
.divider-white.ant-divider-horizontal.ant-divider-with-text:after {
  border-color: var(--white);
}

.ant-card {
  background-color: transparent !important;
  border-radius: 12px !important;
}

.ant-card-body {
  background-color: rgb(41 37 36) !important;
  color: var(--white);
}

.cardBody .ant-card-body {
  background-color: rgb(255, 255, 255) !important;
  color: #000;
}

.cardBody1{
  background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(37,145,251,0.98) 0.1%, rgba(0,7,128,1) 99.8% );
  box-shadow: "0 4px 8px rgb(191, 215, 237)";
}

.cardBody1 .ant-statistic .ant-statistic-content {
  color: rgb(255, 255, 255) !important;
}

.cardBody1 .ant-statistic .ant-statistic-title {
  color: rgb(255, 255, 255) !important;
}

.cardBody1:hover {
  box-shadow: 0 6px 10px rgb(45, 57, 144) !important; 
}

.listItem {
  list-style-type: disc;
  margin-top: 5px;
  padding-left: 30px;
  font-weight: bold;
  font-size: large;
}

.ant-modal-body .ant-radio-wrapper,
.ant-modal-body label,
.ant-form-item-label>label:after,
.dark-table td:not(.ant-table-row-expand-icon-cell),
.dark-table .ant-table-thead>tr>th,
.dark-table .ant-table-tbody>tr>th,
.search-text input,
li.ant-pagination-item a:not(li.ant-pagination-item-active a),
li.ant-pagination-next button,
li.ant-pagination-prev button,
.ant-empty-description,
.sidebar .ant-menu-item-selected,
.sidebar .ant-menu.ant-menu-inline .ant-menu-item:hover,
.ant-empty .ant-empty-description{
  color: var(--white);
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-table-placeholder:hover .ant-empty-description {
  color: #9d9d9d !important;
}

.ant-card-bordered,
.ant-modal-header,
.ant-modal-footer,
.ant-card-head {
  border-color: rgb(41 37 36) !important;
}

.ant-modal-header,
.ant-card-head {
  border-radius: 12px 12px 0 0 !important;
}

.ant-modal-footer,
.ant-card-body {
  border-radius: 0 0 12px 12px !important;
}

.ant-modal-content {
  background-color: transparent !important;
}

.twangometer-target .thermometer .thermometer__mask {
  border-top: 3px solid #0ed200;
  border-radius: 0px;
  z-index: 20;
}

.twangometer-target .thermometer .thermometer__draw-a:after,
.twangometer-target .thermometer .thermometer__draw-a {
  box-shadow: none;
}

.twangometer-target .thermometer__draw-a,
.twangometer-target .thermometer__meter,
.twangometer-target .thermometer .thermometer__bg-color,
.twangometer-target .thermometer--theme-light .thermometer__draw-a,
.twangometer-target .thermometer--theme-light .thermometer__draw-a:before,
.twangometer-target .thermometer--theme-light .thermometer__draw-a:after {
  background: transparent !important;
}

.thermometer .thermometer__bg-color {
  background: linear-gradient(#DC2626, #FDBD66, var(--teal)) !important;
}

.thermometer .thermometer__draw-b:after,
.thermometer .thermometer__draw-b:before {
  background-color: var(--teal) !important;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: rgb(41 37 36) !important;
}

.ant-btn-primary:hover,
.circle-menu:hover {
  background: linear-gradient(180deg, #555555 0%, #7a7a7a 100%) !important;
  color: var(--cream) !important;
}

.ant-btn-primary:focus,
.ant-btn-primary:active,
.circle-menu:focus,
.circle-menu:active {
  background: linear-gradient(180deg, #555555 0%, #767676 100%) !important;
  box-shadow: 0px -1px 1px rgb(255 255 255 / 14%), 0px 0px 10px #a1a1a1, 0px 5px 14px rgb(0 0 0 / 25%), inset 0px 0px 0px 3px rgb(255 255 255 / 40%) !important;
  color: var(--cream) !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--white) !important;
}

.ant-modal-header,
.ant-slider-track,
.ant-radio-inner:after,
.ant-menu-item-selected,
.ant-menu-item:hover {
  background-color: var(--primary) !important;
}

.ant-modal-title,
.ant-modal-close-x {
  color: var(--white) !important;
}

.ant-radio-checked:after {
  border: 1px solid var(--primary) !important;
}

.ant-radio-group-solid:not(.plan-choice) .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #44403C !important;
  border-color: #44403C !important;
}

.ant-radio-group-solid:not(.plan-choice) .ant-radio-button-wrapper {
  background: transparent !important;
  border: 2px solid #44403C !important;
  border-radius: 3px !important;
  color: var(--white) !important;
}

.ant-radio-group-solid:not(.plan-choice) .ant-radio-button-wrapper:not(:first-child):before {
  background-color: #44403C !important;
}

.plan-choice .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  background-color: #263B94 !important;
  border-color: #263B94 !important;
}

.primary-table th,
.primary-table td {
  text-align: center !important;
}

.primary-table tr:first-child th {
  background-color: var(--primary);
  color: var(--white);
}

.primary-table tr:nth-child(2) th {
  background-color: var(--subcolor);
  color: var(--white);
}

.ant-modal-body .ant-card-head {
  background-color: var(--subcolor);
  color: var(--white);
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  background: linear-gradient(180deg, #555555 0%, var(--grey) 100%) !important;
}

.ant-btn-primary[disabled] .stop-btn {
  background-color: rgba(0, 0, 0, .25);
}

/* canvas {
  margin: 0 auto;
} */

.ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table {
  margin: 0 !important;
}

.exp-table .ant-table-row-level-0:not(.ant-table-expanded-row .ant-table-row-level-0) {
  background-color: var(--cream);
}

.ant-col-xs-24 {
  width: 200px;
}

.ant-btn-icon:not(.ant-btn-loading-icon) {
  vertical-align: inherit !important;
  display: flex !important;
  justify-content: center;
}

.ant-upload.ant-upload-drag {
  background: #fafafa;
}

.chart-placement {
  position: relative;
  left: -83px;
}

.x-axis {
  position: relative;
  bottom: 20px;
}

.x-axis-pitch {
  position: relative;
  top: -31px;
}

.y-axis {
  position: relative;
  left: 14px;
}

.ant-btn.ant-btn-primary{
  background: linear-gradient(180deg, #555555 0%, #2C2C2C 100%);
  border: 1px solid var(--white) !important;
}
.ant-btn.ant-btn-primary:hover:not(.ant-btn.ant-btn-circle:hover){
  border: 1px solid var(--white) !important;
}

.ant-btn-circle.ant-btn-lg,
.circle-menu {
  background: linear-gradient(180deg, #555555 0%, #2C2C2C 100%);
  border: 3px solid var(--dark) !important;
  box-shadow: 0px -1px 1px rgb(255 255 255 / 14%), 0px 5px 7px rgb(0 0 0 / 24%), 0px 5px 14px rgb(0 0 0 / 25%), inset 0px 1px 3px 1px rgb(255 255 255 / 40%);
  color: var(--white);
}

.circle-menu {
  background: linear-gradient(180deg, #555555 0%, #2C2C2C 100%) !important;
  color: var(--white) !important;
}

.ant-btn.ant-btn-default:not(.btn-outlined) {
  background:var(--primary);
  border: 1px solid var(--white) !important;
  font-weight: 500 !important;
  color: var(--white) !important;
}

.ant-btn.ant-btn-default:not(.btn-outlined):hover,
.ant-btn.ant-btn-default:not(.btn-outlined):active,
.ant-btn.ant-btn-default:not(.btn-outlined):focus,
.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab:hover,
.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active:hover {
  background:#24255B;
  color: var(--cream) !important;
}
.btn-outlined:hover{
  color: var(--cream) !important;
}
.btn-outlined:disabled{
  background-color: #b2d8fc;
  color: var(--white);
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active {
  background: var(--primary) !important;
  font-weight: 500 !important;
  color: var(--white) !important;
  border-bottom: none !important;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-active) {
  background: linear-gradient(180deg, #555555 0%, #2C2C2C 100%);
  box-shadow: 0px -1px 1px rgb(255 255 255 / 14%), 0px 5px 7px rgb(0 0 0 / 24%), 0px 5px 14px rgb(0 0 0 / 25%), inset 0px 1px 3px 1px rgb(255 255 255 / 40%);
  color: var(--white);
}

.ant-switch {
  background-color: #555555 !important;
}

.ant-switch-checked {
  background-color: var(--teal) !important;
}

/* Draggable modal  */
.flexible-modal {
  position: absolute;
  top: 50%;
  z-index: 1000 !important;
  border: 1px solid #ffedae !important;
  background: #fef7e4 !important;
}

.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}

.flexible-modal-drag-area {
  background: transparent !important;
  width: 79% !important;
  left: 0 !important;
}

.flexible-modal-mask,
.flexible-modal-drag-area-left,
.flexible-modal-drag-area-bottom,
.flexible-modal-drag-area-right,
.thermometer__percent-current,
.thermometer__statistics {
  display: none;
}

.flexible-modal.screen-recorder {
  z-index: 997 !important;
}

.notes-modal .notes-header {
  border-bottom: 2px solid #ffedae;
  background-color: #fbf2d5;
  padding: 17px;
  border-radius: 2px 2px 0 0;
  height: 50px;
}

.notes-header h3 {
  color: rgba(0, 0, 0, .85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.notes-modal::placeholder {
  color: #C0B481;
  font-weight: 700 !important;
}

.notes-modal textarea.ant-input {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: black;
}

/* Speaker chart  */
.expanding-ball {
  /* background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #44403C 100%); */
  background: radial-gradient(50% 50% at 50% 50%, rgba(253, 189, 102, 0.25) 0%, #FDBD66 100%);
  border: 1px solid #000000;
}

.speaker {
  background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 35.68%, #4B4B4B 100%);
  box-shadow: inset 0px 1px 4px 4px rgba(0, 0, 0, 0.25);
}

.speaker-frame::before,
.speaker::after {
  content: '';
  position: absolute;
  z-index: 10;
  background-repeat: no-repeat;
}

.speaker-frame::before {
  background-image: url('./assets/Speaker-Frame.svg');
  width: 230px;
  height: 230px;
}

/* .speaker::after{
  background-image: url('./assets/Mesh.svg');
  width: 100%;
  height: 100%;
} */
.warning-popup .ant-modal-header,
.warning-popup .ant-modal-content,
.warning-popup .ant-modal-body,
.warning-popup .ant-modal-footer {
  background-color: rgb(254 252 232) !important;
}

.ant-btn-sm {
  line-height: 1;
}

.ant-btn-sm span {
  font-size: small;
}

.ant-modal-content {
  padding: 0 !important;
}

.ant-modal-header {
  border-bottom: 1px solid !important;
  padding: 16px 24px !important;
  margin-bottom: 0 !important;
}

.ant-modal-body {
  padding: 24px !important;
}

.ant-modal-footer {
  border-top: 1px solid !important;
  margin-top: 0 !important;
  padding: 10px 16px !important;
}

.ant-card input {
  background-color: #252525 !important;
  color: var(--white) !important;
}

.ant-menu-item-group-title {
  font-size: x-small;
  font-style: italic;
}

.split-form .ant-form-item-label label {
  width: 100%;
}

.split-form .ant-col {
  padding-bottom: 0 !important;
}

.exercise-tab .ant-tabs-tab {
  padding: 2px 10px !important;
}

.exercise-tab .ant-tabs-nav {
  margin-bottom: 8px !important;
}

.rec-both-ico {
  transform: rotate(135deg);
}

.gauge-console {
  background: #B3B3B3;
  border: 8px solid #555555;
  /* box-shadow: inset -6px 4px 5px 7px rgba(0, 0, 0, 0.25); */
  border-radius: 8px;
  position: relative;
  margin: 0 auto;
}

.gauge-console:before {
  background: none;
  border: 1px solid #A2B496;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.gauge-console #gauge-chart5 {
  position: relative;
  top: 8px;
}

.gauge-console label.min,
.gauge-console label.max {
  font-weight: 500;
  position: absolute;
}

.gauge-console label.min {
  left: 8%;
  bottom: 50%;
}

.gauge-console label.max {
  right: 8%;
  bottom: 50%;
}

.transparent-select .ant-select-selector {
  background-color: transparent !important;
}

.transparent-select .ant-select-selection-item,
.transparent-select .ant-select-arrow {
  color: var(--white) !important;
}

.transparent-select .ant-select-selection-placeholder {
  color: rgba(225, 225, 225, 0.30);
}

.ReactPiano__Keyboard {
  height: 210px !important;
}

.ReactPiano__NoteLabel.ReactPiano__NoteLabel--natural {
  font-size: x-large;
  font-weight: 500;
}

.ReactPiano__NoteLabel.ReactPiano__NoteLabel--accidental {
  font-size: large;
}

.ReactPiano__Key--active {
  background: #263C92 !important;
  border-color: #23265B !important;
  /* Change the default active key color to bright red */
}

/* Hid scroll bar, but while still being able to scroll */
#pianosingle {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

#pianosingle::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

#pianoupper {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

#pianoupper::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

#pianolower {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

#pianolower::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.hz-marker .ant-divider-inner-text {
  color: var(--teal);
  background: #e5e5e5;
  border-radius: 15px;
  border: 1px solid var(--teal);
}

.hz-marker .ant-divider-horizontal.ant-divider-with-text {
  border-top-color: var(--teal);
}

.video-flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  pointer-events: none;
}

/* Dark table start */
.dark-table td,
.dark-table tr.ant-table-expanded-row:hover>td,
.dark-table tr.ant-table-expanded-row>td {
  background-color: rgb(41 37 36);
}

.dark-table td,
.dark-table .ant-table-thead>tr>th,
.dark-table .ant-table-tbody>tr>th,
.dark-table tr.ant-table-expanded-row>td .ant-table-wrapper td {
  border-bottom: 1px solid #81598E !important;
}

/* Dark table hover color start */
.dark-table .ant-table-tbody .ant-table-row>.ant-table-cell-row-hover,
.dark-table .ant-table-tbody>tr>td.ant-table-cell-row-hover,
td.ant-table-column-sort,
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: rgb(68 64 60) !important;
}

/* Dark table hover color end */
.dark-table .ant-table-thead>tr>th,
.dark-table .ant-table-tbody>tr>th,
.dark-table tr.ant-table-expanded-row>td .ant-table-wrapper td {
  background-color: rgb(28 25 23);
}

/* Dark table end */
.relative text.current-value {
  rotate: 0deg;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.ant-radio-disabled+span {
  color: rgb(255 255 255 / 25%) !important;
}

.bg-grid .border-white:first-child::after {
  position: absolute;
  top: 40%;
  left: 43%;
  color: var(--white);
}

.ant-input-number-group-addon,
.ant-slider-rail {
  background-color: #f5f5f5 !important;
}

.react-tel-input {
  border-radius: 4px;
}

.react-tel-input .form-control {
  height: 32px !important;
  width: 100% !important;
}

.people-bg {
  background-image: url('./assets/sound-waves.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--white);
}

.plans-table th:first-child {
  background: rgba(29, 145, 158, 0.30) !important;
}

.plans-table th:nth-child(2) {
  background: rgba(129, 89, 142, 0.30) !important;
}

.plans-table td:first-child {
  background: rgba(29, 145, 158, 0.30) !important;
}

.plans-table td:nth-child(2) {
  background: rgba(129, 89, 142, 0.30) !important;
}

a {color: #1677ff  !important;}
.transparent-modal-body .ant-modal-body{
  background-color: transparent !important;
}
.admin-login .ant-ribbon-wrapper{
  width: 100%;
}
.ant-tour-content .ant-btn.ant-btn-primary
{
  background: #2C2C2C;
  border-color: #2C2C2C !important;
  box-shadow: none;
}
.ant-tour-content .ant-btn.ant-btn-primary:focus, 
.ant-tour-content .ant-btn.ant-btn-primary:active,
.ant-tour-content .ant-btn.ant-btn-primary:hover{
  background: #555555 !important;
  border-color: #555555 !important;
  box-shadow: none !important;
}

.ant-tour-content .ant-btn.ant-btn-default
{
  background: var(--primary);
  border-color: var(--primary) !important;
  box-shadow: none;
}
.ant-tour-content .ant-btn.ant-btn-default:focus, 
.ant-tour-content .ant-btn.ant-btn-default:active,
.ant-tour-content .ant-btn.ant-btn-default:hover{
  background: #24255B !important;
  border-color: #24255B !important;
  box-shadow: none !important;
}
.ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu-selected{
  background-color: var(--primary) !important;
}
.profile-popup .ant-modal-body{
  border: 3px solid var(--primary);
  border-radius: 15px;
}
.profile-popup .ant-select-disabled .ant-select-selector{
  background: #cdc9c9 !important;
}
.drag-controls{
  filter: drop-shadow(0 4px 7px rgba(129 ,140 ,248, 0.25));
}
.drag-handle {
  cursor: grab;
}

.drag-handle:active {
  cursor: grabbing;
}
.ant-btn,
.ant-btn-sm {
  line-height: 1 !important;
}
.ant-tour{
  width: 400px;
}

.svg {
  width: '1000';
  height: auto;
  border: 1px solid #ddd; /* Optional: Add a border for better visibility */
}

.text {
  font-family: Arial, sans-serif;
  font-size: 0.75rem;
  text-anchor: middle;
}

.notification-popup .ant-modal-body {
  max-height: 500px;
  overflow-y: auto;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.noti-popover .ant-popover-inner-content{
  max-height: 440px;
  overflow-y: auto;
}

.mobile-menu .ant-drawer-body{
  padding: 0;
}
.mobile-menu .ant-drawer-header{
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
}

@media (min-width: 1024px) {

  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--grey);
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--teal);
  }

  .relative text.current-value {
    rotate: 270deg;
  }
  .admin-login .ant-ribbon-wrapper{
    width: 50%;
  }
}

@media (min-width: 1280px) {
  .loudness-meter g text.segment-value:last-child {
    rotate: 270deg;
    transform: translate(0, 65%);
    -webkit-transform: translate(0, 65%);
  }

  .loudness-meter g text.segment-value:nth-last-child(2) {
    rotate: 223deg;
    transform: translate(0, 65%);
    -webkit-transform: translate(0, 65%);
  }
}

@media (max-width: 640px) {
  .flexible-modal {
    width: 100% !important;
    left: 0 !important;
  }

  .flexible-modal-resizer {
    display: none;
  }
}